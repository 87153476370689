<template>
  <span class="inline-block" v-bind="$attrs" v-show="!show">{{ state }}</span>
  <Input v-model:value="state" v-bind="$attrs" v-show="show" />
  <EditOutlined
    v-show="!show"
    @click="handleClick"
    class="ml-4 cursor-pointer !text-blue !font-normal"
  ></EditOutlined>
  <check-outlined
    @click="handleClick"
    v-show="show"
    class="ml-4 cursor-pointer !text-blue !font-normal"
  />
</template>
<script lang="ts">
  import { defineComponent, ref, computed, unref, watch } from 'vue'
  import { Input } from 'ant-design-vue'
  import { EditOutlined, CheckOutlined } from '@ant-design/icons-vue'
  import { useRuleFormItem } from 'framework/hooks/component/useFormItem'
  import { useAttrs } from 'framework/hooks/core/useAttrs'

  export default defineComponent({
    name: 'InputEdit',
    components: {
      Input,
      EditOutlined,
      CheckOutlined,
    },
    inheritAttrs: false,
    props: {
      value: [Array, Object, String, Number],
    },
    emits: ['change', 'update:value'],
    setup(props, { emit }) {
      const emitData = ref<any[]>([])
      const attrs = useAttrs()
      const show = ref(false)
      const [state] = useRuleFormItem(props, 'value', 'change', emitData)
      const handleClick = () => {
        show.value = !show.value
      }

      const currentField = ref('')
      watch(
        () => state.value,
        (v) => {
          //对比一个唯一值，来判断是新窗口，是的话重置
          if (
            currentField.value !=
            attrs.value.formValues.model[attrs.value.formValues.schema.componentProps.compareFiled]
          ) {
            show.value = false
            currentField.value =
              attrs.value.formValues.model[
                attrs.value.formValues.schema.componentProps.compareFiled
              ]
          }
          emit('update:value', v)
        },
      )
      return { state, attrs, handleClick, show }
    },
  })
</script>
