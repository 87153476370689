<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'categoryTree',
})
</script>
<template>
  <a-range-picker
    style="width: 100%"
    :placeholder="placeholder"
    v-model:value="rangeValue"
    @calendarChange="(par1, par2) => calendarChange(par1, par2)"
    :disabledDate="disabledDate"
    format="YYYY/MM/DD"
    :allowClear="false"
  />
</template>
<script lang="ts" setup name="categoryTree">
  const emits = defineEmits(['change'])

  interface Props {
    placeholder: any
    defaultValue: any
  }
  const props = withDefaults(defineProps<Props>(), {
    placeholder: ['开始时间', '结束时间'],
  })
  const rangeValue = ref<any>([])

  watch(
    () => props.defaultValue,
    (val) => {
      console.log(888, val)

      if (!val.length) rangeValue.value = []
    },
    { deep: true },
  )

  function calendarChange(date: string, dateString: string) {
    emits('change', dateString)
  }
  function disabledDate(current) {
    return current.valueOf() > new Date().valueOf()
  }
</script>
