<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'DataSelect',
})
</script>
<template>
  <a-select
    :value="state.value"
    :mode="props.mode"
    label-in-value
    :placeholder="props.placeholder"
    style="width: 100%"
    :filter-option="false"
    :options="state.options"
    @search="fetchUser"
    :not-found-content="props?.options?.length ? '请搜索' : undefined"
    :maxTagCount="1"
    @change="handleChange"
  >
    <template #maxTagPlaceholder="value">
      <a-tooltip>
        <template #title>{{ value.map((v) => v.label).join(',') }}</template>
        + {{ value.length }} ...
      </a-tooltip>
    </template>
  </a-select>
</template>
<script lang="ts" setup name="DataSelect">
  import { PropType, reactive, watch } from 'vue'
  import { debounce } from 'lodash-es'

  const emits = defineEmits(['change', 'update:value'])

  defineOptions({
    inheritAttrs: true,
  })

  const props = defineProps({
    value: {
      type: Array as PropType<string[] | number[]>,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
    mode: {
      type: String as PropType<'multiple' | 'tags'>,
      default: 'multiple',
    },
    options: {
      type: Array as PropType<{ value: string | number; label: string }[]>,
      default: () => [],
    },
    defaultValue: {
      type: Array as PropType<(string | number)[]>,
      default: () => [],
    },
  })

  const state = reactive<any>({
    options: [],
    value: [],
  })

  const handleChange = (e) => {
    state.value = e
    if (!e?.length) {
      state.options = []
    }
    emits(
      'change',
      e.map((item) => item.value || item),
    )
  }

  watch(
    () => props.value,
    (v) => {
      if (!v?.length) {
        state.options = []
        state.value = []
      }
    },
  )

  watch(
    () => props.defaultValue,
    (v) => {
      state.value = v
      state.options = props.options.filter((item) => v.includes(item.value))
      emits('change', v)
    },
    {
      immediate: true,
    },
  )

  const fetchUser = debounce((value) => {
    if (!value) {
      state.options = []
      return
    }
    state.options = []
    // 大小写不敏感
    const list = props.options.filter((item) =>
      item.label.toLowerCase().includes(value.toLowerCase()),
    )
    state.options = list
  }, 300)
</script>
