<template>
  <div :class="[`flex ${prefixCls}`]" style="border: 1px solid rgba(0, 0, 0, 0.15)">
    <template v-for="(value, key, index) in defaultFields" :key="key">
      <div :class="[`flex ${prefixCls}-item	`]" v-if="value == 'select'">
        <Select
          v-bind="$attrs"
          @change="handleChange"
          :options="getOptions[key]"
          v-model:value="state[key]"
          :placeholder="placeholder[key]"
        >
          <template #[item]="data" v-for="item in Object.keys($slots)">
            <slot :name="item" v-bind="data || {}"></slot>
          </template>
          <template #suffixIcon v-if="loading">
            <LoadingOutlined spin />
          </template>
          <template #notFoundContent v-if="loading">
            <span>
              <LoadingOutlined spin class="mr-1" />
              {{ t('component.form.apiSelectNotFound') }}
            </span>
          </template>
        </Select>
      </div>
      <div
        :class="[`flex items-center ${prefixCls}-item ${prefixCls}-input flex-auto`]"
        v-if="value == 'input'"
      >
        <Input
          :placeholder="placeholder[key]"
          :class="[`!border-none  ${prefixCls}-input-item`]"
          v-model:value="state[key]"
        />
      </div>
    </template>
  </div>
</template>
<script lang="ts">
  import { Select, Input } from 'ant-design-vue'
  import { useAttrs } from 'framework/hooks/core/useAttrs'
  import { LoadingOutlined } from '@ant-design/icons-vue'
  import { useI18n } from 'framework/hooks/web/useI18n'
  import { propTypes } from 'framework/utils/propTypes'
  import { useDesign } from 'framework/hooks/web/useDesign'

  type OptionsItem = { label: string; value: string; disabled?: boolean }

  export default defineComponent({
    name: 'MultipleComponents',
    components: {
      Select,
      Input,
      LoadingOutlined,
    },
    inheritAttrs: false,
    props: {
      value: [Array, Object, String, Number],
      numberToString: propTypes.bool,
      api: {
        type: Function as PropType<(arg?: Recordable) => Promise<OptionsItem[]>>,
        default: null,
      },
      // api params
      params: {
        type: Object as PropType<Recordable>,
        default: () => ({}),
      },
      componentProps: {
        type: Object as PropType<Recordable>,
        default: () => ({}),
      },
      options: {
        type: Object as PropType<Recordable>,
        default: () => ({}),
      },
      placeholder: {
        type: Object as PropType<Recordable>,
        default: () => ({}),
      },
      // support xxx.xxx.xx
      resultField: propTypes.string.def(''),
      labelField: propTypes.string.def('label'),
      valueField: propTypes.string.def('value'),
      defaultValue: propTypes.array.def([]),
      defaultFields: propTypes.any,
      componentMap: propTypes.array.def([]),
      immediate: propTypes.bool.def(true),
      alwaysLoad: propTypes.bool.def(false),
    },
    emits: ['options-change', 'change', 'update:value'],
    setup(props, { emit }) {
      const loading = ref(false)
      const emitData = ref<any[]>([])
      const attrs = useAttrs()
      const { t } = useI18n()
      const state = ref<any>({})
      const { prefixCls } = useDesign('multipleComponents')
      console.log('state.value', state.value)
      const getOptions = computed(() => {
        const { labelField, valueField, options } = props
        for (let i in options) {
          let item: any = options[i]
          item.forEach((item) => {
            item.label = labelField ? item[labelField] : item.label
            item.value = valueField ? item[valueField] : item.value
          })
        }
        return options
      })

      watch(
        () => props.value,
        (v) => {
          state.value = v
        },
        { deep: true },
      )

      watch(
        () => state.value,
        (v) => {
          emit('update:value', v)
        },
      )

      function handleChange(_, ...args) {
        emitData.value = args
      }

      return { state, attrs, getOptions, loading, t, handleChange, prefixCls }
    },
  })
</script>
<style lang="less">
  @prefix-cls: ~'@{namespace}-multipleComponents';

  .@{prefix-cls} {
    > div {
      height: 30px !important;
      line-height: 30px !important;
    }

    .ant-select-selector,
    input {
      height: 30px !important;
      line-height: 30px !important;
    }

    &-item:nth-last-child(1) {
      position: relative;
    }

    &-item:nth-last-child(4) {
      position: relative;

      .ant-select-selector {
        width: 130px;
        border-radius: 2px 0px 0px 2px;
        border: 1px solid transparent;
        background: #f6f7f9;
      }
    }

    &-item:nth-last-child(3) {
      position: relative;

      .ant-select-selector {
        border-radius: 0;
        width: 118px;

        border: 1px solid transparent;
        background: #f6f7f9;
      }
    }

    &-item:last-child {
      &::before {
        content: '';
        width: 10px;
        height: 1px;
        background: #333333;
        position: absolute;
        left: -8px;
        top: 50%;
        z-index: 1;
        transform: translateY(-50%);
        transform: scale(0.8);
      }
    }

    &-input {
      border: 0;

      input {
        padding: 4px 8px;
        // width: 57px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 22px;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
</style>
