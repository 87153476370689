<template>
  <span>{{ state }}</span>
</template>
<script lang="ts">
  import { Select } from 'ant-design-vue'
  import { useRuleFormItem } from 'framework/hooks/component/useFormItem'
  import { LoadingOutlined } from '@ant-design/icons-vue'
  export default defineComponent({
    name: 'ApiSelect',
    components: {
      Select,
      LoadingOutlined,
    },
    inheritAttrs: false,
    props: {
      value: [Array, Object, String, Number],
    },
    emits: ['options-change', 'change', 'update:value'],
    setup(props) {
      // Embedded in the form, ust use the hook binding to perform form verification
      const [state] = useRuleFormItem(props, 'value', 'change')

      return { state }
    },
  })
</script>
